import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { formulari, FormData } from '../content';

type eventHandler = (event: React.FormEvent) => void;

export const FormStep2 = ({ onSubmit, onBack, register, data }: { onSubmit: eventHandler, onBack?: eventHandler, register: any, data: FormData }) => {
    const preguntes = formulari.step2(data).map((p, pidx) => (
        <Form.Row key={p.title} className="align-items-center justify-content-between px-md-5">
            <Form.Group controlId={`formRadios${pidx}`} className="w-100">
                <Form.Label as="legend" className="h5">{p.title}</Form.Label>
                <Form.Text as="p" className="h6">{p.text}</Form.Text>
                <div className="d-flex flex-wrap justify-content-around">
                    {p.checkboxes.map((label, lidx) => (
                        <Form.Check
                            key={label}
                            type="radio"
                            label={label}
                            name={p.name}
                            // no real need for lidx+1 then (but aligns nicely)
                            defaultChecked={p.value ? String(p.value) === String(lidx + 1) : (lidx === 2)}
                            value={lidx + 1}
                            inline
                            ref={register}
                        />
                    ))}
                </div>
            </Form.Group>
        </Form.Row>
    ));
    return (
        <Form onSubmit={onSubmit}>
            {preguntes}
            <hr />
            <Form.Row className="align-items-center justify-content-between px-3 px-md-5">
                {onBack ? (
                    <Button onClick={onBack} variant="light" className="mb-2 rounded-pill text-dark">{formulari.back}</Button>
                ) : ""}
                <Button type="submit" className="mb-2 rounded-pill text-dark">{formulari.next}</Button>
            </Form.Row>
        </Form >
    )
}
