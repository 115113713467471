import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';

const UnderlinedLink = styled(Link)`
    &:hover {
        text-decoration: underline;
    }
`;

interface page {
    link: string
    text: string
    className?: string
}

export const NavBar = ({ title, pages }: { title: string | JSX.Element, pages: page[] }) => {
    return (
        <Navbar collapseOnSelect bg="light" expand="lg" sticky="top">
            <Navbar.Brand as={Link} to="/">
                {typeof title === "string" && <h1 className="text-primary font-weight-bold">{title}</h1>}
                {typeof title !== "string" && title}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" /> {/* TODO icon change in here: https://stackoverflow.com/a/61134859 */}
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {pages && pages.map((p, idx) => (
                        <Nav.Link key={p.link} eventKey={idx} as={p.className ? Link : UnderlinedLink} className={`ml-auto px-4 text-dark ${p.className}`} to={p.link}>
                            {p.text}
                        </Nav.Link>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
