import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

// ref: https://stackoverflow.com/a/12701056
export const StickyFooter = styled(Container)`
    position: relative;
    margin-top: -2.5rem; /* negative to get single-sections pages without scrollbars */
`;

export const Footer = () => {
    const year = new Date(Date.now()).getUTCFullYear();
    return (
        <StickyFooter fluid className="bg-light text-dark align-items-center">
            <Row xs={1} sm={2}>
                <Col className="text-center text-md-left p-2">&copy; ALAE. All rights reserved. {year}</Col>
                <Col className="text-center text-md-right p-2">Disseny gràfic i web per <a target="_blank" rel="noopener noreferrer" href="https://www.helenallop.com">helenallop.com</a></Col>
            </Row>
        </StickyFooter>
    )
}
