import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
interface point {
    index?: JSX.Element
    title?: string | JSX.Element
    text: string | JSX.Element
    className?: string
}

export const Bulletpoints = ({ content, horizontal }: {
    content: {
        title?: string | JSX.Element
        text?: string | JSX.Element
        points: point[]
    },
    horizontal?: boolean
}) => {
    const clamp = (ps: number) => Math.min(Math.max(ps, 2), 4);
    const points = horizontal ? (
        <Row xs={1} sm={clamp(content.points.length)} className="align-baseline">
            {content.points.map((c, idx) => (
                <Col key={idx} className={`text-left mt-3 pl-0 pr-3 mx-0 ${c.className || ''}`}>
                    {c.index}
                    {c.title && typeof c.title === "string" && <h5 className="font-weight-normal text-primary my-3">{c.title}</h5>}
                    {c.title && typeof c.title !== "string" && c.title}
                    {typeof c.text === "string" && <p>{c.text}</p>}
                    {typeof c.text !== "string" && c.text}
                </Col>
            ))}
        </Row>
    ) : content.points.map((c, idx) => (
        <Row key={idx} xs={c.index ? 2 : 1}>
            {c.index && <Col xs={1} className={`text-left mt-3 px-0 ${c.className || ''}`}> {c.index} </Col>}
            <Col xs={c.index ? 11 : 12} className={`text-left mt-3 px-0 ${c.className || ''}`}>
                {c.title && typeof c.title === "string" && <p className="font-weight-normal text-primary">{c.title}</p>}
                {c.title && typeof c.title !== "string" && c.title}
                {typeof c.text === "string" && <p>{c.text}</p>}
                {typeof c.text !== "string" && c.text}
            </Col>
        </Row>
    ));
    return (
        <React.Fragment>
            <div> {/*className="d-inline-flex flex-column flex-wrap">*/}
                {content.title && typeof content.text === "string" && <h1 className="font-weight-bold">{content.title}</h1>}
                {content.title && typeof content.text !== "string" && content.title}
                {typeof content.text === "string" && <p className="my-3">{content.text}</p>}
                {typeof content.text !== "string" && content.text}
            </div>
            <Container fluid>
                {points}
            </Container>
        </React.Fragment>
    )
}
