import React from 'react';

interface content {
    title: string | JSX.Element
    text: string | JSX.Element
    button?: JSX.Element
}

export const Hero = ({ content }: { content: content }) => {
    return (
        <div>
            {content.title && typeof content.title === 'string' && <h1>{content.title}</h1>}
            {content.title && typeof content.title !== 'string' && content.title}
            {content.text && typeof content.text === 'string' && content.text.split("\n").filter(p => p.trim().length > 0).map((p, idx) => (<p key={idx}>{p}</p>))}
            {content.text && typeof content.text !== 'string' && content.text}
            {content.button}
        </div>
    )
}
