import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

// TODO: potentially do container behaviour (PageSection should be ~= section+Container)
export const PageSection = styled.section`
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 5vh 0;
`;

// TODO: this is not working at the moment!
export const Page = styled.div`
    scroll-behavior: smooth;
`;

interface SectionColumn {
    content: JSX.Element
    hideInMobile?: boolean
    main?: boolean | "xl" | "lg"
    className?: string
}

export const TwoColumnsSection = ({ left, right }: { left: SectionColumn, right: SectionColumn }) => {
    const className = (c: SectionColumn) => {
        let classn = c.hideInMobile ? "d-none d-lg-block" : "";
        classn += " px-2 align-self-center";
        if (c.className && c.className.length > 0) classn += ` ${c.className}`
        return classn.trim()
    }
    const [wl, wr] = left.main === right.main ? [6, 6] : left.main === undefined ? [4, 8] : right.main === undefined ? [8, 4] : [6, 6];
    const [wll, wlr] = left.main === right.main ? [6, 6] : left.main === true || left.main === "lg" ? [8, 4] : [4, 8];
    return (
        <Container fluid>
            <Row>
                {left && <Col sm={12} lg={wll} xl={wl} className={className(left)}>{left.content}</Col>}
                {right && <Col sm={12} lg={wlr} xl={wr} className={className(right)}>{right.content}</Col>}
            </Row>
        </Container>
    )
}
