import firebase from 'firebase/app';
import 'firebase/firestore';

let firestore: firebase.firestore.Firestore | undefined = undefined;
export function getFirestore(): firebase.firestore.Firestore | undefined {
    if (firestore !== undefined) return firestore
    if (firebase.apps.length > 0) {
        firestore = firebase.firestore();
        return firestore
    }
    fetch('/__/firebase/init.json')
        .then(async response => {
            // this only works for firebase deploy/serve
            // not for local development
            if (firebase.apps.length < 1) {
                firebase.initializeApp(await response.json());
                firestore = firebase.firestore();
            }
        }).catch(err => {
            // this should work for local development
            const config = {
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            }
            if (firebase.apps.length < 1) {
                console.log("error initializing firebase:", err);
                console.log("initializing in local dev mode");
                firebase.initializeApp(config);
                firestore = firebase.firestore();
            }
        });
    return firestore
}
