import React from 'react';
import './App.scss';
import logo from './images/logo_verd_clar.svg';
import { Switch, Route } from "react-router-dom";
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';
import Home from './pages/Home';
import Recursos from './pages/Recursos';
// import Contact from './pages/Contact';
import Pairing from './pages/Pairing';
import Programa from './pages/Programa';
// import About from './pages/About';
import Formulari from './pages/Formulari';
import { navbar } from './content';


function App() {
    const title = <img src={logo} alt="ALAE" height={60} />;
    const pages = [
        {
            link: "/",
            text: navbar.home,
            page: <Home />,
        },
        {
            link: "/programa",
            text: navbar.program,
            page: <Programa />,
        },
        {
            link: "/recursos",
            text: navbar.resources,
            page: <Recursos />,
        },
        // {
        //     link: "/nosaltres",
        //     text: "Nosaltres",
        //     page: <About />,
        // },
        // {
        //     link: "/contact",
        //     text: navbar.contact,
        //     page: <Contact />,
        //     className: "btn btn-primary rounded-pill text-dark",
        // },
        {
            link: "/form",
            text: navbar.cta,
            page: <Formulari isMentor={false} />,
            className: "btn btn-primary rounded-pill text-dark",
        },
    ];

    return (
        <React.Fragment>
            <div className="bg-light min-vh-100">
                <NavBar title={title} pages={pages} />
                <Switch>
                    {pages && pages.slice(1).map(p => (
                        <Route path={p.link} key={p.link}>
                            {p.page}
                        </Route>
                    ))}
                    <Route path="/mentor">
                        <Formulari isMentor={true} />
                    </Route>
                    <Route path="/pairing">
                        <Pairing />
                    </Route>
                    <Route path="/">
                        {pages[0].page}
                    </Route>
                </Switch>
            </div >
            <Footer />
        </React.Fragment>
    );
}
export default App;
