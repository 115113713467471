import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import firebase from "firebase/app";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import { Page } from "../components/Page";
import { formulari, pairs } from "../content";
import styled from "styled-components";
import { getFirestore } from "../fire";
import * as QueryString from "query-string";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

export const FormCard = styled(Card)`
  background-color: white;
  padding: 5vh;
  max-width: 960px;
  min-height: 540px;
`;

const SortableItem = SortableElement(({text}: {text: string}) => <ListGroup.Item className="user-select-none">{text}</ListGroup.Item>);

const SortableList = SortableContainer(({items}: {items: Item[]}) => {
  return (
    <ListGroup>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.id}`} index={index} text={item.name} />
      ))}
    </ListGroup>
  );
});

const errorPage = (
    <Page className="bg-light">
        <Container className="justify-content-md-center">
            <Card>
                <Card.Title>{formulari.failure}</Card.Title>
                <Card.Body className="px-0"></Card.Body>
            </Card>
        </Container>
    </Page>
);
interface Item {
    id: string
    name: string
}

export default function Contact() {
    let firestore = getFirestore();
    const [modal, setModal] = useState({
        show: false,
        text: "Processant...",
        success: false,
        done: false,
    });
    const onSuccess = () => {
        setModal({
            ...modal,
            show: true,
            done: true,
            success: true,
            text: formulari.success,
        });
    };
    const onError = (error: string) => {
        setModal({
            ...modal,
            show: true,
            done: true,
            success: false,
            text: formulari.failure,
        });
        console.log(error);
    };


    const location = useLocation();
    let { p } = QueryString.parse(location.search);
    if (p === null || Array.isArray(p)) p = "";
    let pairing: Record<string, string> = {};
    try {
        // https://github.com/SortableJS/react-sortablejs
        // test struct: {uuid: "doubvrouab", id1:"Albert R", id2:"Albert B",id3:"Miquel F"}
        // test string: eyJ1dWlkIjoiZG91YnZyb3VhYiIsImlkMSI6IkFsYmVydCBSIiwiaWQyIjoiQWxiZXJ0IEIiLCJpZDMiOiJNaXF1ZWwgRiJ9
        // test url: http://localhost:3000/pairing?p=eyJ1dWlkIjoiZG91YnZyb3VhYiIsImlkMSI6IkFsYmVydCBSIiwiaWQyIjoiQWxiZXJ0IEIiLCJpZDMiOiJNaXF1ZWwgRiJ9
        pairing = JSON.parse(atob(p));
    } catch (error) {
        pairing = {}
    }

    const ids = Object.keys(pairing);
    const initialState = ids.reduce((acc: Item[], id) => {
        if (id === "uuid") return acc
        acc.push({ id: id, name: pairing[id] });
        return acc
    }, []);
    // k => k === "uuid" ? "" : pairing[k]).filter(v => v.length > 0);
    const [state, setState] = useState(initialState);
    const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        setState(arrayMove(state, oldIndex, newIndex));
      };
    const submit = (state: Item[]) => {
        setModal({ ...modal, show: true });
        firestore = getFirestore();
        if (firestore === undefined) {
            return onError("firestore no inicialitzat");
        }
        const result = {
            uuid: pairing.uuid,
            preferences: state.map(v => { return { id: v.id, name: v.name } })
        };
        return firestore
            .collection("pairing")
            .add({
                ...result,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((_) => {
                return onSuccess();
            })
            .catch((err) => {
                return onError(err);
            });
    };
    if (!pairing) return errorPage;

    return (
        <Page className="bg-light">
            <Modal
                show={modal.show}
                onHide={() => setModal({ ...modal, show: false })}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body className="text-center">{modal.text}</Modal.Body>
                <Modal.Footer className="justify-content-center">
                    {!modal.done ? (
                        <Spinner animation="border" />
                    ) : modal.success ? (
                        <Button
                            variant="success"
                            onClick={() => setModal({ ...modal, show: false })}
                        >
                            {formulari.close}
                        </Button>
                    ) : (
                                <Button
                                    variant="danger"
                                    onClick={() => setModal({ ...modal, show: false })}
                                >
                                    {formulari.close}
                                </Button>
                            )}
                </Modal.Footer>
            </Modal>
            <Container className="justify-content-md-center">
                <FormCard className="text-dark my-3 mx-auto shadow-sm">
                    <Card.Title>{pairs.title}</Card.Title>
                    <Card.Body className="px-0">
                        <Form onSubmit={(evt) => {
                            evt.preventDefault();
                            submit(state);
                        }}>
                            <Form.Group className="w-100">
                                <Form.Label>{pairs.text}</Form.Label>
                                <SortableList items={state} onSortEnd={onSortEnd} />
                            </Form.Group>
                            <Button type="submit" className="mb-2 rounded-pill text-dark">
                                {formulari.submit}
                            </Button>
                        </Form>
                    </Card.Body>
                </FormCard>
            </Container>
        </Page>
    );
}
