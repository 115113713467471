import React from 'react';
import compass from './images/compass.svg';
import seedling from './images/seedling.svg';
import tachometer from './images/tachometer.svg';
import styled from 'styled-components';


const HighlightStrong = styled.span`
    font-weight: 600;
    font-size: 5.3rem;
    line-height: 5.3rem;
`;

export const calltoaction = {
    title: <h1 className="font-weight-normal mb-0">Programa de mentoria</h1>,
    // text: <p className="mt-0 mb-4">Propera convocatòria: Tardor de 2023</p>,
    text: <p className="mt-0 mb-4">Sol·licita formar part de la cohort 2023 i creix amb nosaltres (fins al 20 d'octubre).</p>,
    button: "Uneix-t'hi!",
    path: "/form",
};


export const navbar = {
    home: "Inici",
    program: "Programa",
    resources: "Recursos",
    cta: calltoaction.button,
    contact: "Contacta'ns"
}

export const wiift = {
    title: <h1 className="font-weight-bold"><span className="text-primary">Què</span> en trec?</h1>,
    text: <p className="my-3">Alae és un programa de mentoria per a estudiants d'enginyeria aeronàutica.<br />Els nostres 3 objectius són:</p>,
    points: [
        {
            index: <img src={compass} className="d-block w-25" alt="compass" />,
            title: "Orientar",
            text: <p><span className="text-primary">Assignem un mentor</span> per a cada mentorat en base a les seves inquietuds professionals per a assegurar una perspectiva rellevant (l'admissió al programa està subjecte a demanda).</p>,
        },
        {
            index: <img src={seedling} className="d-block w-25" alt="seedling" />,
            title: "Créixer",
            text: <p>Comptem amb experiència en camps tant diversos com el disseny aeronàutic, l'emprenedoria o l'estratègia que posem al teu servei per <span className="text-primary">créixer professional i personalment.</span></p>,
        },
        {
            index: <img src={tachometer} className="d-block w-25" alt="tachometer" />,
            title: "Accelerar",
            text: <p>Aspirem a accelerar les carreres professionals dels nostres mentorats per mitjà d’una <span className="text-primary">una tria de carrera més informada.</span></p>,
        },
    ],
};

export const mentors = {
    title: <h1 className="font-weight-bold"><span className="text-primary">Els nostres</span> mentors</h1>,
    points: [
        {
            text: <p><span className="font-weight-bold">Més de</span> <br />
                <span className="text-primary font-weight-bold"><HighlightStrong>30</HighlightStrong> mentors</span><br />
                Graduats en <span className="font-weight-bold">Enginyeria <br />Aeronàutica</span> a l'ESEIAAT.</p>,
        },
        {
            text: <p><span className="font-weight-bold">Experiència</span> en més de <br />
                <span className="text-primary font-weight-bold"><HighlightStrong>10</HighlightStrong> països</span><br />
                a <span className="font-weight-bold">tots els continents.</span></p>,
        },
        {
            text: <p>Mentors amb <br />
                <span className="text-primary font-weight-bold"><HighlightStrong>5-7</HighlightStrong> anys</span><br />
                de <span className="font-weight-bold">carrera professional</span> en tota mena de posicions i sectors.</p>,
        },
    ]
}

export const requisits = {
    title: <h1 className="font-weight-bold text-primary">Requisits</h1>,
    text: <h4>Per a poder formar part del programa de mentoria caldrà que sigueu alumnes
        <span className="font-weight-bold"> 3r i 4t de grau o de 1r o 2n de Màster d'Aeronàutica a l'ESEIAAT</span>.</h4>
};

export const emparellament = {
    title: <h1 className="font-weight-bold"><span className="text-primary">Inici</span> del<br />programa</h1>,
    text: <p className="my-3">A Alae ens prenem molt seriosament la tasca de trobar el mentor adient per a cada mentorat.</p>,
    points: [
        {
            index: <HighlightStrong className="text-primary">1.</HighlightStrong>,
            text: "Facilitem un qüestionari a cada candidat per a entendre quines són les seves inquietuds professionals.",
        },
        {
            index: <HighlightStrong className="text-primary">2.</HighlightStrong>,
            text: "Creuem les respostes rebudes amb els perfils dels nostres mentors per a treure tres possibles emparellaments.",
        },
        {
            index: <HighlightStrong className="text-primary">3.</HighlightStrong>,
            text: "Organitzem reunions 1:1 amb cadascun dels possibles mentors per assegurar la sintonia mentor-mentorat.",
        },
        {
            index: <HighlightStrong className="text-primary">4.</HighlightStrong>,
            text: "Recollim les dades de tota la resta de reunions d'emparellament i assignem els mentors de manera òptima.",
        },
    ],
};

export const mentoria = {
    title: <h1 className="font-weight-bold"><span className="text-primary">Procés</span><br /> de mentoria</h1>,
    text: <p className="my-3">El procés de mentoria comença un cop s'ha assignat un mentor i té una durada prevista de 6 mesos. Les fases del procés i els seus objectius són:</p>,
    points: [
        {
            index: <HighlightStrong className="text-primary">5.</HighlightStrong>,
            title: "Sessions 1 i 2",
            text: "Construir confiança entre el mentor i el mentorat, a més de proporcionar visibilitat sobre un nombre de sortides professionals el més ampli possible.",
        },
        {
            index: <HighlightStrong className="text-primary">6.</HighlightStrong>,
            title: "Sessions 3 i 4",
            text: "Proporcionar eines i consells per a la cerca de feina, com ara entendre les fortaleses personals, crear un currículum i una cover letter, o aprendre a fer sol·licituds de feina.",
        },
        {
            index: <HighlightStrong className="text-primary">7.</HighlightStrong>,
            title: "Sessions 5 i 6",
            text: "Acompanyar en el procés de cerca de feina amb orientació per a tipus d'entrevista específics (e.g., desenvolupador de software, consultor). Aconsellar sobre com encarar el networking.",
        },
        {
            index: <HighlightStrong className="text-primary">8.</HighlightStrong>,
            title: "Cloenda",
            text: "Finalitzar el programa i establir les bases d'una relació mentor-mentorat més enllà d'aquest.",
        },
    ],
};

export const formulari = {
    step1: {
        name: "Nom",
        surname: "Cognoms",
        email: "Email",
        course: {
            title: "Curs",
            options: ["1r Màster Aeronàutica ESEIAAT", "2n Màster Aeronàutica ESEIAAT",
                "3r Grau Aeronàutica ESEIAAT", "4t Grau Aeronàutica ESEIAAT",
                "Altres"]
        },
        phone: "Telèfon (Whatsapp)",
        terms: (isMentor: boolean) => (<label>He llegit i accepto els <a
            target="_blank" rel="noopener noreferrer"
            href={isMentor ? "/termes_i_condicions_mentors.pdf" : "/termes_i_condicions.pdf"}>termes i condicions </a>
            i la <a
                target="_blank"
                rel="noopener noreferrer"
                href={isMentor ? "/solicitud_dades_mentors.pdf" : "/solicitud_dades.pdf"}>política de protecció de dades</a>
        </label>)
    },
    step2: (data: FormData) => {
        return [
            {
                title: "Pregunta 1",
                text: "D'entrada, t'interessa més l'enginyeria traidicional o els negocis? (1 = enginyeria, 5 = negocis)",
                name: "pregunta1",
                value: data.pregunta1,
                checkboxes: ["1", "2", "3", "4", "5"],
            },
            {
                title: "Pregunta 2",
                text: "Com d'interessat estàs en seguir relacionat amb la indústria aeronàutica/aeroespacial/aerolínies? (1 = gens, 5 = molt)",
                name: "pregunta2",
                value: data.pregunta2,
                checkboxes: ["1", "2", "3", "4", "5"],
            },
            {
                title: "Pregunta 3",
                text: "Com d'interessat estàs en treballar creant un producte (e.g., desenvolupant software, dissenyant un component/sistema aeronàutic, data science)? (1 = gens, 5 = molt)",
                name: "pregunta3",
                value: data.pregunta3,
                checkboxes: ["1", "2", "3", "4", "5"],
            },
            {
                title: "Pregunta 4",
                text: "Estàs interessat en treballar en una empresa petita o gran? (1 = petita, 5 = gran)",
                name: "pregunta4",
                value: data.pregunta4,
                checkboxes: ["1", "2", "3", "4", "5"],
            },
        ]
    },
    step3: (data: FormData, isMentor: boolean) => {
        return {
            checkboxes: [
                {
                    title: "Pregunta 5",
                    text: "Estàs interessat en treballar en recerca? (1 = gens, 5 = molt)",
                    name: "pregunta5",
                    value: data.pregunta5,
                    checkboxes: ["1", "2", "3", "4", "5"],
                },
                {
                    title: "Pregunta 6",
                    text: "Estàs interessat en treballar a l'estranger? (1 = gens, 5 = molt)",
                    name: "pregunta6",
                    value: data.pregunta6,
                    checkboxes: ["1", "2", "3", "4", "5"],
                },
            ],
            final: isMentor !== true ? {
                title: "Pregunta 7",
                text: "Hi ha alguna altra cosa que t'agradaria que tinguéssim en compte?",
                name: "pregunta7",
                value: data.pregunta7,
            } : {
                title: "Perfil de mentor",
                text: "Descriu una mica el teu perfil com a professional:",
                hashtags: {
                    "Àrea professional": ["Espai", "Aeroports / Navegació", "Aerolínies / MRO / CAMO", "Propulsió i Motors", "Logística", "UAV", "Software", "Finaces / Negoci", "Altres indústries"],
                    "Tipus de feina": ["Enginyeria", "Recerca", "Project Management", "Estratègia", "Consultoria", "Emprenidoria", "Petita Empresa", "Gran Empresa"],
                    "Localització": ["Amèrica del Nord", "Catalunya", "Espanya", "Europa", "Orient Pròxim", "Resta del món"],
                } as Record<string, string[]>,
            },
        }
    },
    summary: {
        title: "Assegura't que tot està bé"
    },
    done: {
        title: "Moltes gràcies per inscriure't al programa!",
        body: <div><p>Tot ha funcionat correctament</p>
            <p>Ens posarem en contacte amb tu tan bon punt comencin les rondes de contacte.</p></div>,
        back: "Tornar a l'inici"
    },

    step: "Pas",
    next: "Següent",
    back: "Endarrere",
    submit: "Finalitzar",
    success: "Tot ha anat bé!",
    failure: "Alguna cosa no ha anat bé...",
    close: "Tanca"
}

export const contact = {
    title: navbar.contact,
    custom: "Per què vols participar al programa Alae i què esperes aconseguir-ne?"
}

export const pairs = {
    title: "Re-ordena les opcions en base a les teves preferències",
    text: "Posa a dalt la persona amb qui hagis tingut millor sintonia i al final amb la que menys:"
}

export const videos = [
    { embedId: "_Xl8mf60-fc", title: "ALAE Taula Rodona | Mobilitat internacional enfocada a les àrees de negoci | 2021" },
    { embedId: "jJSiv0U5-QI", title: "ALAE Taula Rodona | Mobilitat internacional enfocada a l'espai | 2021" },
]

export interface FormData {
    name?: string
    surname?: string
    email?: string
    phone?: string
    course?: string
    termsAndConditions?: boolean
    pregunta1?: number
    pregunta2?: number
    pregunta3?: number
    pregunta4?: number
    pregunta5?: number
    pregunta6?: number
    pregunta7?: string
    hashtags?: Record<string, boolean>
}

export const SerializeForm = (data: FormData) => {
    const hashtags = data.hashtags ? data.hashtags : {};
    const filtered = Object.keys(hashtags).filter(k => hashtags[k]).reduce((acc, k) => {
        return { ...acc, [k]: hashtags[k] }
    }, {});
    if (data.pregunta1 !== undefined) data.pregunta1 = Number(data.pregunta1)
    else delete data.pregunta1
    if (data.pregunta2 !== undefined) data.pregunta2 = Number(data.pregunta2)
    else delete data.pregunta2
    if (data.pregunta3 !== undefined) data.pregunta3 = Number(data.pregunta3)
    else delete data.pregunta3
    if (data.pregunta4 !== undefined) data.pregunta4 = Number(data.pregunta4)
    else delete data.pregunta4
    if (data.pregunta5 !== undefined) data.pregunta5 = Number(data.pregunta5)
    else delete data.pregunta5
    if (data.pregunta6 !== undefined) data.pregunta6 = Number(data.pregunta6)
    else delete data.pregunta6
    return {
        ...data,
        hashtags: filtered,
    }
}