import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { Page, PageSection, TwoColumnsSection } from '../components/Page';
import { Hero } from '../components/Hero';
import { Bulletpoints } from '../components/Bulletpoints';

import farverd from '../images/farverd.svg';
import donafosc from '../images/donafosc.svg';
// import donaverd from '../images/donaverd.svg';
import banderaverd from '../images/banderaverd.svg';
import * as cnt from '../content';

export default function Home() {
    const landing = <TwoColumnsSection
        left={{
            hideInMobile: true,
            content: <img className="d-block w-100" src={donafosc} alt="dona" />
        }}
        right={{
            main: true,
            hideInMobile: false,
            content: <Hero content={{
                ...cnt.calltoaction,
                button: (<Button variant="primary" size="lg" as={Link} to="/form" className="px-4 rounded-pill text-dark">
                    {cnt.calltoaction.button}
                </Button>)
            }} />
        }}
    />;
    const wiift = <TwoColumnsSection
        left={{
            hideInMobile: false,
            main: "lg",
            content: <Bulletpoints horizontal content={cnt.wiift} />
        }}
        right={{
            hideInMobile: true,
            main: "xl",
            content: <img className="d-block w-100" src={farverd} alt="far" />
        }}
    />;
    const mentors = <TwoColumnsSection
        left={{
            hideInMobile: false,
            main: "lg",
            content: <Bulletpoints horizontal content={cnt.mentors} />
        }}
        right={{
            hideInMobile: true,
            main: "xl",
            content: <img className="d-block w-100" src={banderaverd} alt="muntanya" />
        }}
    />;
    // const cta = <TwoColumnsSection
    //     left={{
    //         hideInMobile: true,
    //         content: <img className="d-block w-100" src={donaverd} alt="dona" />
    //     }}
    //     right={{
    //         main: true,
    //         hideInMobile: false,
    //         content: (<Hero content={{
    //             ...cnt.calltoaction,
    //             button: (<Button variant="primary" size="lg" as={Link} to={cnt.calltoaction.path} className="px-4 rounded-pill text-dark">
    //                 {cnt.calltoaction.button}
    //             </Button>)
    //         }} />)
    //     }}
    // />;
    const sections = [
        { content: landing, className: "text-dark bg-light" },
        { content: wiift, className: "text-light bg-dark" },
        { content: mentors, className: "text-dark bg-light" },
        // { content: cta, className: "text-light bg-dark" },
    ];
    return (
        <Page>
            {sections.map((s, idx) => (
                <PageSection className={s.className} key={`home_${idx}`}>
                    <Container>
                        {s.content}
                    </Container>
                </PageSection>
            ))}
        </Page>
    )
}
