import React from 'react';
import Container from 'react-bootstrap/Container';

import { PageSection, TwoColumnsSection } from '../components/Page';

import pissarraverd from '../images/pissarraverd.svg';
import { Bulletpoints } from '../components/Bulletpoints';
import { videos } from '../content';

export default function Programa() {

    const embedVideo = ({ embedId, title }: { embedId: string, title: string }) => {
        return {
            text: <iframe width="560" height="315"
                src={`https://www.youtube.com/embed/${embedId}`}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen ></iframe >
        }
    };
    const videosBullets = {
        title: <h3 className="font-weight-bold">Taules Rodones</h3>,
        points: videos.map(embedVideo),
    }

    const videosSection = <TwoColumnsSection
        left={{
            hideInMobile: false,
            main: "lg",
            content: <Bulletpoints content={videosBullets} />
        }}
        right={{
            hideInMobile: true,
            main: "xl",
            content: <img className="d-block w-100" src={pissarraverd} alt="pissarra" />
        }}
    />;

    const sections = [
        { content: videosSection, className: "text-light bg-dark" },
    ];
    return (
        <React.Fragment>
            {sections.map((s, idx) => (
                <PageSection className={s.className} key={`recursos_${idx}`}>
                    <Container>
                        {s.content}
                    </Container>
                </PageSection>
            ))}
        </React.Fragment>
    )
}
