import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { formulari, FormData } from '../content';

type eventHandler = (event: React.FormEvent) => void;

export const FormStep3 = ({ onSubmit, onBack, register, data, isMentor }: { onSubmit: eventHandler, onBack?: eventHandler, register: any, data: FormData, isMentor: boolean }) => {
    const content = formulari.step3(data, isMentor);
    const preguntes = content.checkboxes.map((p, pidx) => (
        <Form.Row key={p.title} className="align-items-center justify-content-between px-md-5">
            <Form.Group controlId={`formRadios${pidx}`} className="w-100">
                <Form.Label as="legend" className="h5">{p.title}</Form.Label>
                <Form.Text as="p" className="h6">{p.text}</Form.Text>
                <div className="d-flex flex-wrap justify-content-around">
                    {p.checkboxes.map((label, lidx) => (
                        <Form.Check
                            key={label}
                            type="radio"
                            label={label}
                            name={p.name}
                            // no real need for lidx+1 then (but aligns nicely)
                            defaultChecked={p.value ? String(p.value) === String(lidx + 1) : (lidx === 2)}
                            value={lidx + 1}
                            inline
                            ref={register}
                        />
                    ))}
                </div>
            </Form.Group>
        </Form.Row>
    ));

    const hashtagSection = content.final.hashtags !== undefined ? (
        <Container fluid>
            {Object.values(content.final.hashtags).map((hs, hsidx) => (
                <div key={`hashtags.${hsidx}`}>
                    {hsidx !== 0 && (<hr />)}
                    <Row xs={1} sm={2} lg={3}>
                        {hs.map((h, idx) => (
                            <Col key={`hashtags.${h}.${hsidx}.${idx}`}>
                                <Form.Check
                                    type="checkbox"
                                    label={h}
                                    name={`hashtags.${h}`}
                                    defaultChecked={data.hashtags && data.hashtags[h]}
                                    ref={register}
                                    inline
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </Container>
    ) : "";
    return (
        <Form onSubmit={onSubmit}>
            {!isMentor && preguntes}
            <Form.Row className="align-items-center justify-content-between px-md-5">
                {
                    content.final.name !== undefined && (
                        <Form.Group controlId="textArea" className="w-100">
                            <Form.Label as="legend" className="h5">{content.final.title}</Form.Label>
                            <Form.Text as="p" className="h6">{content.final.text}</Form.Text>
                            <Form.Control as="textarea" rows={3}
                                name={content.final.name}
                                defaultValue={content.final.value}
                                ref={register} />
                        </Form.Group>
                    )
                }
                {
                    content.final.hashtags !== undefined && (
                        <Form.Group controlId="hashtags" className="w-100">
                            <Form.Label as="legend" className="h5">{content.final.title}</Form.Label>
                            <Form.Text as="p" className="h6">{content.final.text}</Form.Text>
                            {hashtagSection}
                        </Form.Group>
                    )
                }
            </Form.Row>
            <hr />
            <Form.Row className="align-items-center justify-content-between px-3 px-md-5">
                {onBack ? (
                    <Button onClick={onBack} variant="light" className="mb-2 rounded-pill text-dark">{formulari.back}</Button>
                ) : ""}
                <Button type="submit" className="mb-2 rounded-pill text-dark">{formulari.next}</Button>
            </Form.Row>
        </Form >
    )
}
