import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Switch, Route, useHistory, useRouteMatch, Link } from "react-router-dom";
import { StateMachineProvider, createStore, useStateMachine } from 'little-state-machine';
import firebase from 'firebase/app';
import ProgressBar from "react-bootstrap/ProgressBar";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Page } from '../components/Page';
import { FormSummary } from '../form/FormSummary';
import { FormStep1 } from '../form/FormStep1';
import { FormStep2 } from '../form/FormStep2';
import { FormStep3 } from '../form/FormStep3';
import { FormData, SerializeForm, formulari } from '../content';
import styled from 'styled-components';
import { getFirestore } from '../fire';

export const FormCard = styled(Card)`
    background-color: white;
    padding: 5vh;
    max-width: 960px;
    min-height: 540px;
`;

enum Step {
    Step1 = "",
    Step2 = "/step2",
    Step3 = "/step3",
    Summary = "/summary",
    Success = "/success",
}

function FormulariImpl({ isMentor }: { isMentor: boolean }) {
    let firestore = getFirestore();

    const [modal, setModal] = useState({ show: false, text: "Processant...", success: false, done: false });
    const onSuccess = (state: FormData, _?: any) => {
        setModal({ ...modal, show: true, done: true, success: true, text: formulari.success });
        history.push(`${url}${Step.Success}`);
        return {}
    };
    const onError = (state: FormData, payload: { error: any }) => {
        setModal({ ...modal, show: true, done: true, success: false, text: formulari.failure });
        console.log(payload.error);
        history.push(url);
        return { ...state }
    };

    const validData = (state: FormData): boolean => {
        const validEmail = state.email !== undefined && state.email.length > 2;
        const validCourse = state.course !== undefined && state.course.length > 2;
        const validPhone = state.phone !== undefined && state.phone.length > 2;
        const validName = state.name !== undefined && state.name.length > 0 && state.surname !== undefined && state.surname.length > 0;
        return validEmail &&
            ((validCourse && isMentor !== true) || (validPhone && isMentor === true)) &&
            validName &&
            state.termsAndConditions === true
    }

    const { actions, state } = useStateMachine<FormData>({
        next: (state: FormData, payload: { data: FormData, stepTo: Step }) => {
            history.push(`${url}${payload.stepTo}`);
            return { ...state, ...payload.data }
        },
        submit: (state: FormData, _?: any) => {
            setModal({ ...modal, show: true });
            firestore = getFirestore();
            if (firestore === undefined) {
                return onError(state, { error: "firestore no inicialitzat" })
            }
            if (!(validData(state))) {
                return onError(state, { error: "falten algunes respostes" })
            }
            if (isMentor) {
                return firestore.collection("mentors").add({
                    ...SerializeForm(state),
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                })
                    .then(_ => { return onSuccess(state) })
                    .catch(err => { return onError(state, { error: err }) });
            }
            return firestore.collection("mentorats").add({
                ...SerializeForm(state),
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
                .then(_ => { return onSuccess(state) })
                .catch(err => { return onError(state, { error: err }) });
        },
        back: (state: FormData, payload: { stepTo: Step }) => {
            history.push(`${url}${payload.stepTo}`);
            return { ...state }
        },
        success: onSuccess,
        error: onError,
    });

    const history = useHistory();
    const { path, url } = useRouteMatch();

    const { register, handleSubmit } = useForm<FormData>({ defaultValues: state });

    return (
        <Page className="bg-light" >
            <Modal
                show={modal.show}
                onHide={() => setModal({ ...modal, show: false })}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body className="text-center">{modal.text}</Modal.Body>
                <Modal.Footer className="justify-content-center">
                    {!modal.done ?
                        (<Spinner animation="border" />) :
                        modal.success ?
                            (<Button variant="success" onClick={() => setModal({ ...modal, show: false })}>{formulari.close}</Button>) :
                            (<Button variant="danger" onClick={() => setModal({ ...modal, show: false })}>{formulari.close}</Button>)
                    }
                </Modal.Footer>
            </Modal>
            <Container className="justify-content-md-center">
                <Switch>
                    <Route exact path={`${path}`}>
                        <FormCard className="text-dark my-3 mx-auto shadow-sm">
                            <ProgressBar className="my-4" now={0} />
                            <Card.Title>{`${formulari.step} 1`}</Card.Title>
                            <Card.Body className="px-0">
                                <FormStep1
                                    isMentor={isMentor}
                                    data={state}
                                    register={register}
                                    onSubmit={handleSubmit(data => actions.next({ data: data, stepTo: isMentor ? Step.Step3 : Step.Step2 }))} />
                            </Card.Body>
                        </FormCard>
                    </Route>
                    <Route path={`${path}${Step.Step2}`}>
                        <FormCard className="text-dark my-3 mx-auto shadow-sm">
                            <ProgressBar className="my-4" now={isMentor ? 0 : 100 / 4} />
                            <Card.Title>{`${formulari.step} 2`}</Card.Title>
                            <Card.Body className="px-0">
                                <FormStep2
                                    data={state}
                                    register={register}
                                    onSubmit={handleSubmit(data => actions.next({ data: data, stepTo: Step.Step3 }))}
                                    onBack={handleSubmit(_ => actions.back({ stepTo: Step.Step1 }))}
                                />
                            </Card.Body>
                        </FormCard>
                    </Route>
                    <Route path={`${path}${Step.Step3}`}>
                        <FormCard className="text-dark my-3 mx-auto shadow-sm">
                            <ProgressBar className="my-4" now={isMentor ? 100 / 3 : 200 / 4} />
                            <Card.Title>{`${formulari.step} ${isMentor ? 2 : 3}`}</Card.Title>
                            <Card.Body className="px-0">
                                <FormStep3
                                    isMentor={isMentor}
                                    data={state}
                                    register={register}
                                    onSubmit={handleSubmit(data => actions.next({ data: data, stepTo: Step.Summary }))}
                                    onBack={handleSubmit(_ => actions.back({ stepTo: isMentor ? Step.Step1 : Step.Step2 }))}
                                />
                            </Card.Body>
                        </FormCard>
                    </Route>
                    <Route path={`${path}${Step.Summary}`}>
                        <FormCard className="text-dark my-3 mx-auto shadow-sm">
                            <ProgressBar className="my-4" now={isMentor ? 200 / 3 : 300 / 4} />
                            <Card.Title>{formulari.summary.title}</Card.Title>
                            <Card.Body className="px-0">
                                <FormSummary
                                    isMentor={isMentor}
                                    data={state}
                                    onSubmit={handleSubmit(actions.submit)}
                                    onBack={handleSubmit(_ => actions.back({ stepTo: Step.Step3 }))}
                                />
                            </Card.Body>
                        </FormCard>
                    </Route>
                    <Route path={`${path}${Step.Success}`}>
                        <FormCard className="text-dark my-3 mx-auto shadow-sm">
                            <ProgressBar className="my-4" now={100} />
                            <Card.Title>{formulari.done.title}</Card.Title>
                            <Card.Body className="px-0">
                                {formulari.done.body}
                            </Card.Body>
                            <Card.Footer className="px-0 bg-white text-center">
                                <Button variant="primary" className="rounded-pill text-dark" size="lg" as={Link} to="/">{formulari.done.back}</Button>
                            </Card.Footer>
                        </FormCard>
                    </Route>
                </Switch>
            </Container>
        </Page>
    )
}

createStore<FormData>({});
export default function Formulari({ isMentor }: { isMentor: boolean }) {
    return (
        <StateMachineProvider><FormulariImpl isMentor={isMentor} /></StateMachineProvider>
    )
}
