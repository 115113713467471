import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { formulari, FormData } from '../content';

type eventHandler = (event: React.FormEvent) => void;

export const FormStep1 = ({ onSubmit, register, data, isMentor }: { onSubmit: eventHandler, register: any, data: FormData, isMentor: boolean }) => {
    return (
        <Form onSubmit={onSubmit}>
            <Form.Row className="align-items-center justify-content-between px-md-5">
                <Form.Group controlId="formName" as={Col} xs={12} sm={6} md={5}>
                    <Form.Label>{formulari.step1.name}</Form.Label>
                    <Form.Control
                        name="name"
                        className="mb-2 rounded-pill"
                        defaultValue={data.name}
                        required
                        ref={register}
                    />
                </Form.Group>
                <Form.Group controlId="formSurname" as={Col} xs={12} sm={6} md={5}>
                    <Form.Label>{formulari.step1.surname}</Form.Label>
                    <Form.Control
                        name="surname"
                        className="mb-2 rounded-pill"
                        defaultValue={data.surname}
                        required
                        ref={register}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row className="align-items-center justify-content-between px-md-5">
                <Form.Group controlId="formEmail" as={Col} xs={12} sm={6} md={5}>
                    <Form.Label>{formulari.step1.email}</Form.Label>
                    <FormControl
                        className="mb-2 rounded-pill"
                        type="email"
                        name="email"
                        defaultValue={data.email}
                        required
                        ref={register}
                    />
                </Form.Group>
                {
                    isMentor !== true && (
                        <Form.Group controlId="formCurs" as={Col} xs={12} sm={6} md={5}>
                            <Form.Label>{formulari.step1.course.title}</Form.Label>
                            <Form.Control
                                className="mb-2 rounded-pill"
                                as="select"
                                custom
                                name="course"
                                defaultValue={data.course}
                                ref={register}
                            >
                                {formulari.step1.course.options.map(v => <option key={v}>{v}</option>)}
                            </Form.Control>
                        </Form.Group>)
                }
                {
                    isMentor === true && (
                        <Form.Group controlId="formPhone" as={Col} xs={12} sm={6} md={5}>
                            <Form.Label>{formulari.step1.phone}</Form.Label>
                            <FormControl
                                className="mb-2 rounded-pill"
                                type="tel"
                                name="phone"
                                defaultValue={data.phone}
                                required
                                ref={register}
                            />
                        </Form.Group>)
                }
            </Form.Row>
            <hr />
            <Form.Row className="align-items-center justify-content-between px-3 px-md-5">
                <Form.Check
                    type="checkbox"
                    id="autoSizingCheck"
                    name="termsAndConditions"
                    className="mb-2"
                    label={formulari.step1.terms(isMentor)}
                    defaultChecked={data.termsAndConditions}
                    required
                    ref={register}
                />
                <Button type="submit" className="mb-2 rounded-pill text-dark">{formulari.next}</Button>
            </Form.Row>
        </Form >
    )
}
