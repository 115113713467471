import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { FormData, formulari } from '../content';
import styled from 'styled-components';

const StyledLabel = styled.label`
    font-weight: 400;
`;

type eventHandler = (event: React.FormEvent) => void;


export const FormSummary = ({ onSubmit, onBack, data, isMentor }: { onSubmit: eventHandler, onBack?: eventHandler, data: FormData, isMentor: boolean }) => {
    const step3 = formulari.step3(data, isMentor);
    return (
        <Form onSubmit={onSubmit}>
            <Row className="align-items-center justify-content-between px-md-5">
                <Col xs={12} sm={6} md={5}>
                    <StyledLabel>{formulari.step1.name}:</StyledLabel> {data.name}
                </Col>
                <Col xs={12} sm={6} md={5}>
                    <StyledLabel>{formulari.step1.surname}:</StyledLabel> {data.surname}
                </Col>
            </Row>
            <Row className="align-items-center justify-content-between px-md-5">
                <Col xs={12} sm={6} md={5}>
                    <StyledLabel>{formulari.step1.email}:</StyledLabel> {data.email}
                </Col>
                {
                    isMentor !== true && (
                        <Col xs={12} sm={6} md={5}>
                            <StyledLabel>{formulari.step1.course.title}:</StyledLabel> {data.course}
                        </Col>
                    )}
                {
                    isMentor === true && (
                        <Col xs={12} sm={6} md={5}>
                            <StyledLabel>{formulari.step1.phone}:</StyledLabel> {data.phone}
                        </Col>
                    )}
            </Row>
            <Row className="align-items-center justify-content-between px-md-5">
                {formulari.step2(data).map(p => {
                    if (!p.value) return <></>
                    return <Col key={`summary${p.title}`} xs={12} sm={6} md={5}>
                        <StyledLabel>{p.title}:</StyledLabel> {p.value}
                    </Col>
                })}
                {step3.checkboxes.map(p => {
                    if (!p.value) return <></>
                    return <Col key={`summary${p.title}`} xs={12} sm={6} md={5}>
                        <StyledLabel>{p.title}:</StyledLabel> {p.value}
                    </Col>
                })}
            </Row>
            <Row className="align-items-center justify-content-between px-md-5">
                <Col xs={12}>
                    <StyledLabel>{step3.final.title}:</StyledLabel> {step3.final.value !== undefined && step3.final.value}
                    {data.hashtags !== undefined && Object.keys(data.hashtags).filter(k => data.hashtags !== undefined && data.hashtags[k]).join(", ")}
                </Col>
            </Row>
            <hr />
            <Row className="align-items-center justify-content-between px-3 px-md-5">
                {onBack ? (
                    <Button onClick={onBack} variant="light" className="mb-2 rounded-pill text-dark">{formulari.back}</Button>
                ) : ""}
                <Button type="submit" className="mb-2 rounded-pill text-dark">{formulari.submit}</Button>
            </Row>
        </Form >
    )
}
